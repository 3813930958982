<template>
    <div class="contain_box">
        <div class="head_title">
            <div class="left_title">
                <div class="pic_box"></div>
                <div class="data_box" v-if="this.videoData.title">
                    <p style="cursor: pointer;" @click="jumpToGoods">{{this.videoData.title}}</p>
                    <div>
                        <span style="color: #FF0000">￥</span>
                        <span style="font-size:24px;font-weight:bold;color: #FF0000;">{{this.videoData.price}}</span>
                        <span style="margin-left: 10px;text-decoration:line-through">原价:￥{{this.videoData.market_price}}</span>
                    </div>
                    <div style="margin-top: 10px;">
                        <span>抖音浏览量:{{this.videoData.monthly_views}}</span>
                        <span style="margin-left: 10px;">全网销量：{{this.videoData.sales}}</span>
                    </div>
                </div>
                <div v-else>暂无数据</div>
            </div>
        </div>
        <div class="divider">
            <el-divider></el-divider>
        </div>
        <div class="main_box">
            <div class="left-item-fan" v-show="sales_7.length">
                <h4 class="item-half-sub">抖音销量日增量</h4>
                <div class="left-item-content">
                    <div class="fan-tab">
                        <div class="item" :class="{current: fanTabIndex === 0}" @click="toggleFanTab(0)">近7天</div>
                        <div class="item" :class="{current: fanTabIndex === 1}" @click="toggleFanTab(1)">30天</div>
                    </div>
                    <div id="fansGrowth" :style="{width: '1200px', height: '442px'}"></div>
                </div>
            </div>
            <!--            <div class="left-item-fan">-->
            <!--                <h4 class="item-half-sub">抖音浏览量日增量</h4>-->
            <!--                <div class="left-item-content">-->
            <!--                    <div class="fan-tab">-->
            <!--                        <div class="item" :class="{current: fanTabIndex1 === 0}" @click="toggleFanTab1(0)">近7天</div>-->
            <!--                        <div class="item" :class="{current: fanTabIndex1 === 1}" @click="toggleFanTab1(1)">30天</div>-->
            <!--                    </div>-->
            <!--                    <div id="fansGrowth1" :style="{width: '100%', height: '442px'}"></div>-->
            <!--                </div>-->
            <!--            </div>-->
        </div>
    </div>
</template>

<script>
    export default {
        props: ["aweme_id"],
        data() {
            return {
                id: "",
                videoData: {},
                tableData: [],
                fanTabIndex: 0,
                fanTabIndex1: 0,
                sales_7: [],
                sales_30: [],
            }
        },
        mounted() {
            this.getVideoDetail();
        },

        methods: {
            getVideoDetail() {
                let param = {
                    aweme_id: this.aweme_id ? this.aweme_id : this.id
                };
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param, res => {
                    if (res.code == 200) {
                        this.videoData = res.data.video_goods;
                        if (res.data.goods_chart_data.length > 0) {
                            this.sales_7 = res.data.goods_chart_data.data7;
                            this.salsFun(this.sales_7);
                            this.sales_30 = res.data.goods_chart_data.data30;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            jumpToGoods() {
                window.open(this.videoData.url);
            },

            toggleFanTab(index) {
                // console.log(index)
                this.fanTabIndex = index;
                if (this.fanTabIndex == 0) {
                    this.salsFun(this.sales_7);
                } else {
                    this.salsFun(this.sales_30);
                }
            },
            toggleFanTab1(index) {
                // console.log(index)
                this.fanTabIndex1 = index;
                if (this.fanTabIndex1 == 0) {
                    this.viewsFun(this.view_7);
                } else {
                    this.viewsFun(this.view_30);
                }
            },
            dateFormatTwo: function (time) {
                var date = new Date(time * 1000);
                var year = date.getFullYear();
                var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
                var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
                var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
                var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
                var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
                // 拼接
                return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
            },
            salsFun(fansTrendArry) {
                // console.log(fansTrendArry)
                // 时间和数据

                let time = [],
                    data = [];
                for (let i in fansTrendArry) {
                    // 保存时间和数据
                    time.push(this.dateFormatTwo(fansTrendArry[i].update_time).substring(0, 10));
                    data.push(fansTrendArry[i].sales / 1000);
                }
                this.fansGrowth = this.$echarts.init(document.getElementById('fansGrowth'));
                this.fansGrowth.setOption({
                    title: {
                        x: 'center',
                        y: 'bottom',
                        textStyle: { //设置主标题风格
                            Color: '#333333',//设置主标题字体颜色
                            fontSize: 14,
                            fontStyle: 'normal',
                        },
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                background: '#6a7985'
                            }
                        }
                    },
                    xAxis: {
                        data: time,
                        //设置网格线颜色
                        boundaryGap: false,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        splitLine: {
                            show: true,
                            lineStyle: {
                                color: ['#f1f1f1'],
                            }
                        },
                        //隐藏刻度线
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false,
                            lineStyle: {
                                color: '#999999'
                            }
                        },
                        axisLabel: {
                            fontSize: '14',
                            marginTop: '35px',
                            formatter: '{value}W',
                            show: true,
                        },
                    },
                    series: [{
                        data: data,
                        type: 'line',
                        lineStyle: {
                            color: '#20C997',
                            width: 3,
                        },
                        areaStyle: {
                            color: '#d8f6ec'
                        },
                        smooth: true,
                        symbol: "none",
                    }]
                }, true);
            },
            // viewsFun(fansTrendArry) {
            //     // console.log(fansTrendArry)
            //     // 时间和数据
            //     let time = [],
            //         data = [];
            //     for (let i in fansTrendArry) {
            //         // 保存时间和数据
            //         time.push(fansTrendArry[i].time);
            //         data.push(fansTrendArry[i].view / 1000);
            //     }
            //     this.fansGrowth1 = this.$echarts.init(document.getElementById('fansGrowth1'));
            //     this.fansGrowth1.setOption({
            //         title: {
            //             x: 'center',
            //             y: 'bottom',
            //             textStyle: { //设置主标题风格
            //                 Color: '#333333',//设置主标题字体颜色
            //                 fontSize: 14,
            //                 fontStyle: 'normal',
            //             },
            //         },
            //         xAxis: {
            //             data: time,
            //             //设置网格线颜色
            //             boundaryGap: false,
            //             axisLine: {
            //                 show: true,
            //                 lineStyle: {
            //                     color: '#999999'
            //                 }
            //             },
            //         },
            //         yAxis: {
            //             type: 'value',
            //             splitLine: {
            //                 show: true,
            //                 lineStyle: {
            //                     color: ['#f1f1f1'],
            //                 }
            //             },
            //             //隐藏刻度线
            //             axisTick: {
            //                 show: false
            //             },
            //             axisLine: {
            //                 show: false,
            //                 lineStyle: {
            //                     color: '#999999'
            //                 }
            //             },
            //             axisLabel: {
            //                 fontSize: '14',
            //                 marginTop: '35px',
            //                 formatter: '{value}W',
            //                 show: true,
            //             },
            //         },
            //         series: [{
            //             data: data,
            //             type: 'line',
            //             lineStyle: {
            //                 color: '#20C997',
            //                 width: 3,
            //             },
            //             areaStyle: {
            //                 color: '#d8f6ec'
            //             },
            //             smooth: true,
            //             symbol: "none",
            //         }]
            //     }, true);
            // },
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;

        .head_title {
            height: 80px;
            display: flex;
            justify-content: space-between;

            .left_title {
                margin-left: 40px;
            }
        }

        .divider {
            padding: 5px 20px 0 20px;
        }

        .main_box {
            padding-top: 20px;
            margin-left: 40px;

            .left-item-fan {
                margin-bottom: 20px;

                .item-half-sub {
                    font-size: 16px;
                    line-height: 24px;
                }

                .left-item-content {
                    background: #fff;
                    margin-top: 15px;
                    padding: 15px;

                    .fan-tab {
                        text-align: right;
                        padding-right: 30px;
                        color: #999;
                        line-height: 1;

                        .item {
                            display: inline-block;
                            padding: 0 10px;
                            border-right: 1px solid #999;
                            cursor: pointer;

                            &:hover.item {
                                color: #20C997;
                            }

                            &:current {
                                color: #20C997;
                            }

                            &:last-of-type {
                                border-right: 0;
                                padding: 0 0 0 10px;
                            }
                        }
                    }
                }
            }
        }

        .current {
            color: #20C997;
        }
    }
</style>