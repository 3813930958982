<template>
    <div class="contain_box">
        <div class="left_box">
            <div class="gender-box">
                <span class="title">性别分布</span>
                <div class="gender-chart">
                    <div class="icon-box">
                        <i class="iconfont" style="font-size: 20px">&#xe6a6;</i>
                        <i class="iconfont" style="font-size: 20px">&#xe6a8;</i>
                    </div>
                    <div class="chart-content">
                        <div class="man" :style="{width: this.man+'%'}"></div>
                        <div class="women"></div>
                    </div>
                    <div class="bottom-box">
                        <div class="box1">
                            <span>男</span>
                            <span>{{this.man}}%</span>
                        </div>
                        <div class="box1">
                            <span>女</span>
                            <span>{{this.woman}}%</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="age-distribution">
                <span class="title">年龄分布</span>
                <div id="ageDistribution" style="width: 100%;height: 275px;"></div>
            </div>
        </div>
        <div class="right_box">
            <div class="title">
                <span>地区分布</span>
                <span style="float: right;">
                    <el-radio-group v-model="radio" size="small" @change="getListByCh">
                        <el-radio-button label="0">省份</el-radio-button>
                        <el-radio-button label="1">城市</el-radio-button>
                    </el-radio-group>
                </span>
            </div>
            <div class="area-distribution">
                <div class="title">
                    <span>名称</span>
                    <span>占比</span>
                </div>
                <div class="content">
                    <div class="area-item" v-for="item in areaData">
                        <span class="area-name">{{item.areaName}}</span>
                        <el-progress :percentage="item.percentage" :show-text="false" :stroke-width="12"></el-progress>
                        <span style="width: 50px;text-align: center" class="percentage">{{item.percent}}%</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["aweme_id"],
        data() {
            return {
                id: "",
                radio: 0,
                tableData: {},
                genderData: [],
                areaData: [],
                man: 0,
                woman: 0,
            }
        },
        created() {
            this.getVideoDetail();
            if (this.$route.query.aweme_id != "") {
                this.id = this.$route.query.aweme_id;
            }
        },
        mounted() {
            this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
        },
        methods: {
            getListByCh() {
                this.areaData = [];
                if (this.radio == 0) {
                    this.areaDataFun(this.tableData.province_summary);
                } else {
                    this.areaDataFun(this.tableData.city_summary);
                }
            },
            getVideoDetail() {
                let param = {
                    aweme_id: this.aweme_id ? this.aweme_id : this.id
                };
                this.$httpStudent.axiosGetBy(this.$api.hotPeopleVideoDetails, param, res => {
                    if (res.code == 200) {
                        this.tableData = res.data.video_fansData.data;
                        this.areaDataFun(this.tableData.province_summary);
                        this.genderData = this.tableData.gender_summary;
                        this.ageDistributionFun(this.tableData.age_summary);
                        let sum = this.genderData[0].count + this.genderData[1].count + this.genderData[2].count;
                        this.man = Math.round(this.genderData[0].count / sum * 10000) / 100.00;
                        this.woman = Math.round(this.genderData[1].count / sum * 10000) / 100.00;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            areaDataFun(arry) {
                //地区数据处理
                if(arry.length>0){
                    let sum = arry[0].ratio;
                    let sum1 = 0;
                    for(let i in arry){
                        sum1 += arry[i].ratio;
                    }
                    for (let i in arry) {
                        let obj = {
                            areaName: arry[i].keyword,
                            percentage: Math.round(arry[i].ratio / sum * 10000) / 100.00,
                            percent: Math.round(arry[i].ratio / sum1 * 10000) / 100.00,
                        };
                        this.areaData.push(obj);
                    }
                }
            },
            ageDistributionFun(array) {
                //年龄段数据处理
                let ageArray = [], dataArray = [];
                for (let i in array) {
                    ageArray.push(array[i].keyword);
                    let obj = {
                        value: array[i].count,
                        name: array[i].keyword
                    };
                    dataArray.push(obj)
                }
                // ageArray.sort();
                this.ageDistribution = this.$echarts.init(document.getElementById('ageDistribution'));
                this.ageDistribution.setOption({
                    color: {
                        type: 'linear',
                        x: 0.5,
                        y: 0.5,
                        r: 0.5,
                        colorStops: [{
                            offset: 1, color: '#25C495' // 0% 处的颜色
                        }, {
                            offset: 0, color: '#039167' // 100% 处的颜色
                        }],
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: ageArray,
                            axisTick: {
                                alignWithLabel: true
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#ccc"
                                }
                            }
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value',
                            axisLine: {       //y轴
                                show: false
                            },
                            axisTick: {       //y轴刻度线
                                show: false
                            },
                            splitLine: {     //网格线
                                show: false
                            },
                            axisLabel: {
                                formatter: function () {
                                    return ""
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            barWidth: '30%',
                            itemStyle: {        //上方显示数值
                                normal: {
                                    label: {
                                        show: true, //开启显示
                                        position: 'top', //在上方显示
                                        textStyle: { //数值样式
                                            color: '#ccc',
                                            fontSize: 16
                                        }
                                    }
                                }
                            },
                            data: dataArray,
                        }
                    ]
                }, true);
            },
        }
    }
</script>

<style scoped lang="scss">
    .contain_box {
        height: 100%;
        display: flex;
        justify-content: space-between;

        .left_box {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            border-right: 1px solid #eaeaea;
            padding: 0 20px;

            .gender-box {
                display: flex;
                flex-direction: column;
                margin-bottom: 50px;

                .gender-chart {
                    .icon-box {
                        display: flex;
                        justify-content: space-between;
                        color: #34aa7f;
                    }

                    .chart-content {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        height: 20px;

                        .man {
                            background-color: #34aa7f;
                        }

                        .women {
                            flex: 1;
                            background-color: #46dbab;
                        }
                    }

                    .bottom-box {
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;

                        .box1 {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }

            .age-distribution {
                .title {
                    font-size: 14px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                }
            }
        }

        .right_box {
            display: flex;
            flex-direction: column;
            width: 50%;
            height: 100%;
            padding: 0 20px;

            .area-distribution {
                flex: 1;
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                height: calc(100% - 66px);

                .title {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    padding-right: 5px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333;
                }

                .content {
                    flex: 1;
                    padding-right: 5px;
                }

                .area-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 15px;

                    ::v-deep .el-progress {
                        width: 80%;
                        padding: 0 10px;
                    }

                    ::v-deep .el-progress-bar__inner {
                        background: linear-gradient(45deg, #ffa72a, #f8b95f 98%);
                    }
                }
            }
        }
    }
</style>